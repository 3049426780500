
/* Custom Scrollbar */
*::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }
  
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #81c8f6;
  }
body {
    overflow-x: hidden;
    background-color: #060b27 !important;
    color:#fff !important;
  font-size: 15px !important;
}
.css-26l3qy-menu {
    background: #000 !important;
    background-color: #000 !important;
    box-shadow: 0 3px 10px #ffffff30 !important; 
}
.css-1n7v3ny-option{
    background: #5087c0 !important;
}
#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}
.img-fluid.proofThumb {
    max-height: 135px;
    border-radius: 5px;
    display: block;
    margin: 15px 0 0;
}


#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}
#menu-toggle {
    color: #fff;
    border: 2px solid #fff;
    padding: 5px 7px;
    margin: 0 0px 0 0;
    line-height: 0;
    position: fixed;
    top: 15px;
    z-index: 9;
    left: 15px;
}
.navbar.adminNav{
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
        margin-top: 1%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
    .adminNav .dropdown-menu a{
        display: block;
        color: #000;
        padding:8px 10px;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

.container input {
     position: static !important;
     opacity: 1 !important;
     cursor: pointer !important;
     height: auto !important;
     width: 100% !important;
}

.container [type=checkbox] {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    height: 0 !important;
    width: 0 !important;
}

.chip-body{
    height: 37px !important
}
.multiSelect{
    margin :0 !important;
}

.tableHead h2 {
    font-size: 24px;
    color: #d6b032;
    text-align: left;
    margin: 12px 15px 15px;
    font-weight: 600;
}
@media (max-width: 767px) {
    .adminNav .dropdown-menu{
        background: transparent;
        margin-top: 0;
        padding-top: 0;
        border: 0;
    }
    .adminNav .dropdown-menu a{
        display: block;
        color: #fff;
        padding:10px 0;
    }
    .adminNav .dropdown-menu a:hover{
        color: #d6b032;
        text-decoration: none;
    }
    .adminNav .dropdown-item:hover, .adminNav .dropdown-item:focus{
        background: transparent;
        text-decoration: none;
    }
    .adminNav.navbar-dark .navbar-toggler{
        color: #fff;
        border: 2px solid #fff;
        padding: 5px 7px;
        margin: 0 0px 0 0;
        line-height: 0;
        font-size: inherit;
        outline: none;
    }
    .navbar.adminNav{
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
}
}

.border-right
{
  border-color: #0e0e0e !important;
  border-right: 1px solid #0e0e0e !important;
}
.container
{
    padding-left: 15px !important;
}
.navbar-brand
{
    max-width: 150px;
}
.bg-dark.adminNav
{
    background-color: #02071d !important;
}
.asrt-td-loading
{
    background-color: #03081f !important;
}
.no_warp_flex_input
{
    flex-wrap: nowrap !important;
}
.scrollbar_sidebar
{
    width: 250px;
    max-height: calc(100vh - 65px);
    overflow-y:auto;
    scrollbar-color: #81c8f6 #0d2348;
    scrollbar-width: thin;
}
html,body{
    scrollbar-color: #81c8f6 #0d2348;
    scrollbar-width: thin;
}
.text-danger
{
    color:red !important;
}
#approve-aml-modal
{
    background: #212121bd;
}
.radio_aml .form-check
{
margin-bottom: 20px;
}
.user_mangt_table th:nth-child(3),
.user_mangt_table td:nth-child(3),
.user_mangt_table th:nth-child(2),
.user_mangt_table td:nth-child(2)

{
min-width: 200px;
}
.user_mangt_table th:nth-child(6),
.user_mangt_table td:nth-child(6)

{
min-width: 150px;
}

.user_mangt_table th:nth-child(10),
.user_mangt_table td:nth-child(10),
.user_mangt_table th:first-child,
.user_mangt_table td:first-child

{
min-width: 200px;
}