@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060b27 !important;
  color:#fff !important;
  font-size: 15px !important;

}
.flexHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

header, main, footer {
  padding-left: 300px;
}
.list-group-item-action:hover, .list-group-item-action:focus{
  z-index: auto !important;
}
@media screen and (min-width:768px){

}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}

.list-group-flush .list-group-item
{
  background-color:#02071d;
  color:#fff !important;
  border: 1px solid rgb(149 144 144 / 13%);
  font-weight: 500 !important;
}
.list-group-flush .list-group-item:hover,.list-group-flush .list-group-item.active,
.list-group-flush .list-group-item:focus
{
  color:#fff !important;
  background-color: #162b4d  !important;
  border-color: #162b4d  !important;
}
.border-right
{
  border-color: #0e0e0e !important;
  border-right: 1px solid #0e0e0e !important;
}
.card 
{
  background-color: #02071d !important;
    border-radius: 15px !important;
    box-shadow: 0 0 20px 1px #dbe2e92e !important;
}
.form_header
{
  color: #76b7e9 !important;
  font-size: 25px !important;
  font-weight: 600;
}
.text-primary,h2.text-primary
{
  color:#fff !important;
}
h2.text-primary
{
  font-size: 22px !important;
  font-weight: 600;
}
.container input
{
  background-color: #03081f;
    border: 1px solid #81c8f6;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
}
label{
  font-size: 14px !important;
    display: block;
    width: 100%;
    color: #c1c2ca !important;
    margin-bottom: 10px;
}
.btn-primary
{
  background: #5087c0;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    background-color: #5087c0;
    color: #fff;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    /* margin-left: auto;
    margin-right: auto; */
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-primary:hover
{
  background: transparent !important;
    color: #81c8f6 !important;
    border-color: #81c8f6 !important;
}
.font_15
{
  font-size: 15px !important;
}
.link_blue_new
{
  color: #81c8f6;
    font-size: 15px !important;
    transition: .5s;
}
.link_blue_new:hover
{
  color: #ffff !important;
}
.flex_center
{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
@media only screen and (max-width:575px)
{
  .mt_seact_date
  {
    margin-top: 20px;
  }
  .table_filter
  {
    margin-top: 20px !important;
  }
  .table-head .text-right,.table-head .col-md-6
  {
    text-align: center !important;
  }
  .table-foot .col-md-6,.table-foot .col-md-6.text-right
  {
    margin-bottom: 20px !important;
    text-align: center !important;
  }
  .table-foot .col-md-6.text-right
  {
    float: unset !important;
  }
  .table-foot .col-md-6.text-right .pull-right
  {
    float: unset !important;
  }
  .table-foot .col-md-6.text-right .pull-right .justify-content-end
  {
    justify-content: center !important;
  }
  #page-content-wrapper h3
  {
    font-size: 22px !important;
  }
  .flex_center .card
  {
      min-width: 290px !important
  }
}
@media only screen and (min-width:576px)
{
  .mt_seact_date
  {
    margin-top: 35px;
  }
  .flex_center .card
  {
      min-width: 380px !important
  }
}
.text-danger
{
  font-size: 14px !important;
}
.bg-dark.adminNav
{
    background-color: #02071d !important;
    z-index: 9;
}
h3.text-secondary
{
  color:#fff !important;
}
.border-right,div.border-right {
  border-right: 1px solid #212224 !important;
}
.btn-light
{
  background: #5087c0 !important;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    color: #fff !important;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    /* margin-left: auto; */
    /* margin-right: auto; */
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-light .fa
{
  color:#fff !important;
}
.btn-light svg
{
  color:#fff !important;
  fill:#fff !important;
}
.btn-light:hover
{
  background: transparent !important;
  color: #81c8f6 !important;
  border-color: #81c8f6 !important;
}
.btn-light:hover svg
{
  color: #81c8f6 !important;
}
.asrt-page-length .input-group-addon
{
  background: transparent !important;
  color:#fff !important;
}
.asrt-page-length .input-group-addon .input-group-text
{
  color:#fff !important;
}
#page-content-wrapper
{
  padding-bottom: 40px !important;
}
#as-react-datatable-container select
{
  background-color: #03081f;
    border: 1px solid #81c8f6;
    color: #fff !important;
    min-height: 45px !important;
    border-radius: 8px !important;
}
.form-control,.form-control:focus,.form-control:focus-visible,.form-control:hover
{
  background-color: #03081f !important;
    border: 1px solid #81c8f6 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
}
.as-react-table
{
  margin-top: 30px !important;
}
.btn-outline-primary,.btn-danger,.btn-secondary
{
  background: transparent !important;
    color: #fff !important;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7 !important;
    /* margin-left: auto;
    margin-right: auto; */
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-outline-primary:hover,.btn-danger:hover,.btn-secondary:hover
{
  background: #5087c0 !important;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%) !important;
}
.table thead th
{
  color: #fff !important;
    font-size: 15px;
    font-weight: 600;
  border-color:#161c37 !important;

}
.table tbody td
{
  border-bottom: 1px solid #161c37 !important;
    color: #636fa0 !important;
    font-weight: 500 !important;
  border-color:#161c37 !important;
  font-size: 14px !important;

}
.table-bordered
{
  border-color:#161c37 !important;
}
.page-item.disabled .page-link,.page-link
{
  background-color: transparent !important;
  border-color: #161c37 !important;
}
.page-link input,.page-link input:focus-visible
{
  background-color: transparent !important;
  color:#fff !important;
  border:none !important;
}
@media only screen and (max-width:991px)
{
  .sidebar_whole
  {
    position: fixed !important;
    left: 0px !important;
    top:63px !important;
    min-height: 100vh;
    width: 250px !important;
    z-index: 99 !important;
  }
}
.dropdown-menu
{
  background-color: #02071d !important;
}
.adminNav .dropdown-menu a
{
  color:#fff !important;
  text-decoration: none !important;
}
.adminNav .dropdown-menu a:hover
{
  color:#5087c0 !important;
}
.navbar-dark .navbar-nav .nav-link
{

  color:#fff !important;
}
.page-link
{
  color:#fff !important;
}
.page-link:hover
{
  color:#5087c0 !important;
}
.user_dt_table th:last-child,.user_dt_table td:last-child
{
min-width: 275px !important;
}
.pair_dt_table th:last-child,.pair_dt_table td:last-child
{
min-width: 145px !important;
}

.login_hist_table th:last-child,.login_hist_table td:last-child
{
min-width: 145px !important;
}

.login_hist_table th:nth-child(3),.login_hist_table td:nth-child(3)
{
min-width: 200px !important;
}



.withdraw_dt_table th:nth-child(6),.withdraw_dt_table td:nth-child(6)
{
min-width: 200px !important;
}

.deposit_dt_table th:first-child,.deposit_dt_table td:first-child
{
  min-width: 180px !important;
}
.currency_dt_table th:last-child,.currency_dt_table td:last-child
{
  min-width: 150px !important;

}
.resonsive_table .table-body .col-md-12
{
    display: block;
    width: 100%;
    overflow-x: auto;
    scrollbar-color: #81c8f6 #0d2348;
    scrollbar-width: thin;
}
#page-content-wrapper
{
  padding-top: 80px !important;
  /* min-width: calc(100vw - 30px) !important; */
}
.navbar.adminNav
{
  position: fixed !important;
  width: 100% !important;
}




@media only screen and (min-width:992px)
{
    #page-content-wrapper {
        min-width: 0;
        width: calc(100% - 250px) !important;
        min-width: calc(100% - 250px) !important;

    margin-top: 1%;
    margin-left: 250px;
    }
    .shrink_whole #page-content-wrapper
    {
        width: 100% !important;
        margin-left: 0px !important;

    }

    .sidebar_whole
    {
      position: fixed !important;
      left: 0px !important;
      top:63px !important;
      min-height: 100vh;
      width: 250px !important;
      z-index: 99 !important;
    }
}

.modal-content
{
  background-color: #03081f !important;
  box-shadow: 0 0 20px 1px #dbe2e92e;
  border-radius: 10px !important;
}
.close
{
  color:#fff !important;
}
button:focus,.btn:focus
{
  outline: 0px auto -webkit-focus-ring-color !important;
}
.modal-title
{
  font-size: 22px !important;
}
.modal-header,.modal-footer
{
  border-color: #161c37 !important;

}
.modal
{
  padding-right: 0px !important;
}
.table-foot
{
  margin-top: 20px !important;
}
.border_blue_select
{
  border: none !important;
    background: transparent !important;
  /* border:none !important; */
  /* background: transparent !important; */
  /* color: #fff !important; */
 
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  background-color: transparent !important;
  border: 1px solid #81c8f6 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
    color:#fff !important;
  box-shadow: 0 0 0 0rem #fff !important;
  height: 52px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  cursor: pointer;
}
.order_history_table th:last-child,.order_history_table td:last-child
{
  min-width: 180px !important;
}
.trade_history_table th:last-child,.trade_history_table td:last-child
{
  min-width: 180px !important;
}
.perpetual_table th:last-child,.perpetual_table td:last-child
{
  min-width: 150px !important;
}

.img_form
{
  max-width: 200px !important;
}
.btn-primary .fa
{
  color:#fff !important;
}
.modal.show
{
  padding-left: 0px !important;
}

.perpetual_order_table th:first-child,.perpetual_order_table td:first-child
{
  min-width: 150px !important;
}

.perpetual_trade_table th:first-child,.perpetual_trade_table td:first-child
{
  min-width: 150px !important;
}
.liquidated_table th:last-child,.liquidated_table td:last-child
{
  min-width: 150px !important;
}
/* .tox .tox-edit-area__iframe
{
  background-color: transparent !important;
  color:#fff !important;
}
#notes_ifr,#notes_ifr p,.mce-content-body,.mce-content-body p,.tox-editor-container p,#tinymce,#tinymce p
{
  color:#fff !important;

}
.tox-edit-area
{
  filter: brightness(0) invert(1);
}
.tox .tox-toolbar-overlord,.tox .tox-toolbar__primary
{
  background-color: transparent !important;
}
.tox .tox-tbtn,.tox .tox-tbtn svg
{
  color:#fff !important;
  fill:#fff !important;

}
.tox .tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover,.tox .tox-tbtn:hover
{
  background: #02071d !important;
  box-shadow: 0 0 0 0px #dee0e2 inset !important;
}
.tox.tox-tinymce-aux .tox-toolbar__overflow,.tox .tox-tbtn:focus
{
  background: #02071d !important;
  border-color:#81c8f6 !important;
}
.tox-tinymce,.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)
{
  border-color:#81c8f6 !important;

}
.tox .tox-split-button:hover
{
  box-shadow: 0 0 0 0px #dee0e2 inset !important;

}
.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg {
  fill: #ccc !important;
}
.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary
{
  background: transparent !important;
}
.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)
{
  border-right: none !important;
}
.tox-tinymce
{
  border-radius: 10px !important;
}
.tox .tox-statusbar,.tox .tox-statusbar a, .tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount
{
  background: transparent !important;
  color:#FFF !important;

} */
.tox .tox-statusbar
{
  /* border-top: 1px solid #81c8f6 !important; */
  height: 40px !important;
}
/* #notes_ifr
{
  border-top:1px solid #81c8f6 !important;
}
.tox .tox-toolbar-overlord, .tox .tox-toolbar__primary
{
  background: transparent !important;

} */
/* .tox .tox-edit-area__iframe p,#tinymce,.mce-content-body,.tox .tox-edit-area__iframe body
{
  color:#fff !important;

} */
.btn-success
{
  background: #5087c0 !important;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    background-color: #5087c0 !important;
    color: #fff;
    border-radius: 25px !important;
    padding: 7px 20px !important;
    border: 1px solid #5087c0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    /* margin-left: auto; */
    /* margin-right: auto; */
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    box-shadow: 0 0 9px 1px #2765a7 !important;
}
.btn-success:hover
{
  background: transparent !important;
    color: #81c8f6 !important;
    border-color: #81c8f6 !important;
}

.copy_trade_table th:nth-child(3),.copy_trade_table td:nth-child(3)
{
  min-width: 150px !important;
}
.copy_trade_table th:last-child,.copy_trade_table td:last-child
{
  min-width:230px !important;
}
.email_table th:nth-child(3),.email_table td:nth-child(3)
{
  min-width: 150px !important;
}
.email_table .btn-primary {display: inline;}
.cms_table th:last-child,.cms_table td:last-child
{
  min-width: 150px !important;
}

.faq_table th:last-child,.faq_table td:last-child
{
  min-width: 150px !important;
}

.contact_table th:last-child,.contact_table td:last-child
{
  min-width: 150px !important;
}
.contact_table th:nth-child(3),.contact_table td:nth-child(3)
{
  min-width: 150px !important;
}
.chip-body
{
background-color: #02071d !important;
}
.multiSelect input
{
  background-color: #02071d !important;
  border: 1px solid #81c8f6 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
}
.multiSelect .container
{
padding-left:30px !important;
}
.search-result
{
  border-left: 1px solid #81c8f6 !important;
    border-right: 1px solid #81c8f6 !important;
    border-bottom: 1px solid #81c8f6 !important;
    border-radius: 10px !important;
}

.support_table th:last-child,.support_table td:last-child
{
min-width: 150px !important;
}
.category_table th:last-child,.category_table td:last-child
{
min-width: 150px !important;
}
.article_table th:nth-child(2),.article_table td:nth-child(2)
{
min-width: 200px !important;
}
.article_table th:last-child,.article_table td:last-child
{
min-width: 200px !important;
}
.help_table th:nth-child(2),.help_table td:nth-child(2)
{
min-width: 200px !important;
max-width: 200px !important;
}
.help_table th:last-child,.help_table td:last-child
{
min-width: 200px !important;
max-width: 200px !important;
}
.subcategory_table th:nth-child(2),.subcategory_table td:nth-child(2)
{
min-width: 200px !important;
}
.subcategory_table th:last-child,.subcategory_table td:last-child
{
min-width: 200px !important;
}
.helpcentre_table th:nth-child(2),.helpcentre_table td:nth-child(2)
{
min-width: 200px !important;
}
.helpcentre_table th:nth-child(3),.helpcentre_table td:nth-child(3)
{
min-width: 200px !important;
}
.helpcentre_table th:last-child,.helpcentre_table td:last-child
{
min-width: 200px !important;
}
.form-control:disabled, .form-control[readonly]
{
  background: #3e4669 !important;
}
.input_grp_btn
{
  background-color: #03081f !important;
    border: 1px solid #81c8f6 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
}
.input_grp_btn input,.input_grp_btn input:hover,.input_grp_btn input:focus
{
  border: none !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.input_grp_btn .input-group-text
{
background-color: transparent !important;
border:none !important;
}
.no_underline
{
  text-decoration: none !important;
}
.input_grp_width
{
  background-color: #03081f !important;
    border: 1px solid #81c8f6 !important;
    color: #fff !important;
    min-height: 52px !important;
    border-radius: 10px !important;
}
.input_grp_width input,.input_grp_width input:hover,.input_grp_width input:focus
{
  border: none !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}
.input_grp_width .input-group-append,
.input_grp_width .input-group-text
{
  background-color: #03081f !important;
  color: #fff !important;
  border:none !important;
  border-radius: 0px 10px 10px 0px;

}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color: transparent !important;
  background: transparent !important;

    /* -webkit-box-shadow: 0 0 0 30px white inset !important; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid inherit;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #03081f inset;
  color:#fff !important;
  transition: background-color 5000s ease-in-out 0s;
}
input:-internal-autofill-selected 
{
  color:#fff !important;

}

form .col-md-3
{
  display: flex;
  align-items: flex-start;
}
form .col-md-3 label
{
  margin-top: 15px;
}
form .col-md-3.email_align_left_news
{
  align-items: flex-start !important;
}
form .col-md-3.email_align_left_news label
{
  margin-top: 15px;
}

form .col-md-3.new_image_align_start
{
  align-items: flex-start !important;
}


form .col-md-3 label
{
  margin-bottom: 0px !important;
}
form .col-md-9 .custom-file-upload
{
  margin-bottom: 0px !important;

}
.thumb-vertical {
  background: #81c8f6 !important;
}
.select__value-container
{
  padding-right: 40px !important;
}
.react-datepicker-wrapper
{
  width: 100%;
}

.navbar_mobile_menu
{
  position: fixed;
  right: 15px;
}

.navbar_mobile_menu .settings_head_menu 
{
left:unset !important;
right: 0px !important;
position: absolute !important;
z-index: 2;
border: 1px solid #413f3f !important;
}

@media only screen and (max-width:767px)
{
  .pading_remove_mobil
  {
    width: calc(100% + 30px) !important;
    /* padding-left: 0px !important;
    padding-right: 0px !important; */
  }
  .adminNav .navbar_mobile_menu .dropdown-menu a
  {
    padding: 8px 10px !important;
    font-size: 14px !important;
  }
}

.adminNav .navbar_mobile_menu .dropdown-menu a
{
  font-size: 14px !important;
}

.Toastify__toast-container--top-center
{
  top: 1em;
    left: unset !important;
    margin-left: unset !important;
    right: 0% !important;
}

.action .btn,.btn
{
	display: inline-block !important;
}
.container .formcur_default input
{
cursor: default !important;
}
.border_blue_select  svg
{
  cursor: pointer;
}
.modal-backdrop.show
{
  opacity: 0.95 !important;
}
/* .scrolbar_sidebr
{
  height: calc(100vh - 65px) !important;
} */

.btn-rec
{
    background: transparent!important;
    border: 1px solid #81c8f6!important;
    color: #fff!important;
    text-transform: capitalize!important;
    font-weight: 600!important;
    box-shadow: none!important;
    transition: .5s;
    font-size: 14px!important;
}
.btn-rec:hover {
  background: #81c8f6!important;
}
.filter_date_text
{
  color:#81c8f6 !important;
  font-size: 16px !important;
  margin-top: 20px;
}

.table_insde_modal th:nth-child(2),
.table_insde_modal td:nth-child(2)
{
  min-width:150px;
}

.table_insde_modal th:last-child,
.table_insde_modal td:last-child
{
  min-width:200px;
}


.table_insde_modal .page-item .page-link
{
  padding-left: 7px !important;
  padding-right: 7px !important;
  font-size: 12px !important;
}